import acpPaywallsTemplate from './templates/acp-paywalls.html';
// @ngInject
function acpPaywallsDirective() {
  return {
    restrict: 'E',
    scope: {
      paywallTitle: '@',
      type: '@',
      purpose: '=',
      esignDescription: '='
    },
    template: acpPaywallsTemplate
  };
}

export default acpPaywallsDirective;
