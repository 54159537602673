// @ngInject
function AcpPaywallsModel($q, acpFeatureModel, nsUtil) {
  function hasActionRunIntoWall(paywallAction) {
    var defer = $q.defer();

    getFeature(paywallAction.feature).then(function(feature) {
      if (paywallAction.reduce) {
        feature = paywallAction.reduce(feature);
      }
      evaluteFeatureAgainstAction(paywallAction, feature, defer);
    });

    return defer.promise;
  }

  function getFeature(feature) {
    return acpFeatureModel.getGatewayFeature(feature);
  }

  function checkAlias(
    paywallAction,
    aliasName,
    alias,
    features,
    unservicableFeatures,
    walls
  ) {
    if (!isFeatureCompleted(features, aliasName)) {
      var feature;
      for (feature in alias.requirements) {
        if (!isFeatureCompleted(features, feature)) {
          if (isUnknownFeature(paywallAction, feature)) {
            // A Feature We do not know about...they will need to call customer service
            unservicableFeatures.push(paywallAction.requirements[feature]);
          } else {
            walls[feature] = paywallAction.requirements[feature];
          }
        }
      }
    }
  }

  function isUnknownFeature(paywallAction, feature) {
    return paywallAction.requirements[feature] === undefined;
  }

  function isFeatureCompleted(features, feature) {
    return features[feature] === true;
  }

  function evaluteFeatureAgainstAction(paywallAction, features, defer) {
    var featuresLocal = features.features;
    var unservicableFeatures = [];
    var walls = {};

    var feature;
    for (feature in featuresLocal) {
      var conquered = isFeatureCompleted(featuresLocal, feature);

      if (!conquered) {
        var aliasedFeature =
          paywallAction.aliases === undefined
            ? undefined
            : paywallAction.aliases[feature];

        if (aliasedFeature !== undefined) {
          checkAlias(
            paywallAction,
            feature,
            aliasedFeature,
            featuresLocal,
            unservicableFeatures,
            walls
          );
        } else if (isUnknownFeature(paywallAction, feature)) {
          unservicableFeatures.push(paywallAction.requirements[feature]);
        } else {
          walls[feature] = paywallAction.requirements[feature];
        }
      }
    }

    if (unservicableFeatures.length > 0) {
      defer.reject({
        selfServiceable: false,
        unservicableFeatures: unservicableFeatures,
        walls: []
      });
    } else {
      var wallArray = [];
      var wall;
      for (wall in walls) {
        wallArray.push(walls[wall]);
      }

      walls = wallArray;

      // Sort based on order
      // Some walls should come at the end (i.e. ones handled by forklifted pages)
      walls.sort(function(left, right) {
        return left.order - right.order;
      });

      // Covert to wall names only
      var i;
      for (i = 0; i < walls.length; i++) {
        walls[i] = walls[i].wall;
      }

      defer.resolve({
        selfServiceable: true,
        unservicableFeatures: [],
        walls: walls
      });
    }
  }

  return {
    evaluate: function(paywallAction) {
      return hasActionRunIntoWall(paywallAction);
    },
    shouldReEvaluate: function(wallSet, wallName) {
      var shouldReEvaluate = false;

      nsUtil.forIn(wallSet.requirements, function(req) {
        if (req.wall === wallName && req.reEvaluate) {
          shouldReEvaluate = req.reEvaluate;
        }
      });

      return shouldReEvaluate;
    },
    reEvaluate: function(wallSet, wallName) {
      var deferred = $q.defer();

      nsUtil.forIn(wallSet.requirements, function(req, feature) {
        if (req.wall === wallName) {
          getFeature(feature).then(function(data) {
            deferred.resolve(data.features);
          });
        }
      });

      return deferred.promise;
    }
  };
}

export default AcpPaywallsModel;
