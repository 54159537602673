// @ngInject
function AcpPaywallComponentCtrl(
  $scope,
  $q,
  acpCoreDispatcher,
  acpPaywallsModel,
  ACP_PAYWALLS,
  nsUtil
) {
  var deferers = {};

  function init() {
    $scope.paywalls = [];
    $scope.checkingWalls = true;

    var paywall = ACP_PAYWALLS[$scope.type];
    if (!paywall) {
      throw 'Invalid Paywall Defined: ' + $scope.type;
    }

    acpPaywallsModel.evaluate(paywall).then(
      function(result) {
        $scope.checkingWalls = false;

        if (result.walls && result.walls.length > 0) {
          setupWalls(result.walls);
        } else {
          notifyPaywallsFinished();
        }
      },
      function() {
        $scope.checkingWalls = false;

        notifyPaywallsFailed({
          mustContactCS: true,
          bailed: false,
          wall: ''
        });
      }
    );
  }

  function setupWalls(paywalls) {
    $scope.paywalls = paywalls;
    $scope.steps = [];
    $scope.currentStep = 0;

    for (var i = 0; i < $scope.paywalls.length; i++) {
      var paywall = $scope.paywalls[i];

      // Don't add CIP as a step if it's the first because the user can't
      // self-serve out of it
      if (
        paywall.indexOf('cip') === -1 ||
        (paywall.indexOf('cip') !== -1 && i > 0)
      ) {
        $scope.steps.push({
          key: paywall,
          name: paywall,
          pagination: paywall.indexOf('cip') === -1
        });
      }

      deferers[paywall] = $q.defer();
      deferers[paywall].promise.then(bustThroughWall, crashIntoWall);
    }
  }

  function bustThroughWall() {
    // Some walls (e.g. CIP) can be re-evaluated in real time to see if a
    // previous wall cleared them automatically (e.g. Card Activation)
    if (
      acpPaywallsModel.shouldReEvaluate(
        ACP_PAYWALLS[$scope.type],
        $scope.paywalls[$scope.currentStep + 1]
      )
    ) {
      // Check this wall's features again
      acpPaywallsModel
        .reEvaluate(
          ACP_PAYWALLS[$scope.type],
          $scope.paywalls[$scope.currentStep + 1]
        )
        .then(onWallReEvaluated);
    } else {
      // Business as usual
      $scope.currentStep++;

      if ($scope.paywalls.length === $scope.currentStep) {
        notifyPaywallsFinished();
      }
    }
  }

  function onWallReEvaluated(data) {
    var passed = true;

    // Does user have all this wall's features now?
    nsUtil.forIn(data, function(feature) {
      if (!feature) {
        passed = false;
      }
    });

    if (passed) {
      // Skip the next wall
      $scope.currentStep += 2;
    } else {
      // Show the next wall like normal
      $scope.currentStep++;
    }

    if ($scope.paywalls.length === $scope.currentStep) {
      notifyPaywallsFinished();
    }
  }

  function crashIntoWall(result) {
    notifyPaywallsFailed({
      mustContactCS: false,
      bailed: true,
      wall: result.wall
    });
  }

  function notifyPaywallsFinished() {
    acpCoreDispatcher.paywalls.passed.emit();
  }

  function notifyPaywallsFailed(data) {
    acpCoreDispatcher.paywalls.failed.emit(data);
  }

  $scope.isMultiWall = function() {
    return $scope.paywalls.length > 1;
  };

  $scope.currentPaywall = function() {
    return $scope.paywalls[$scope.currentStep];
  };

  $scope.getDefer = function(paywall) {
    return deferers[paywall];
  };

  $scope.hideTitle = function() {
    return $scope.paywalls[$scope.currentStep] === 'acp-anytime-alerts-paywall';
  };

  init();
}

export default AcpPaywallComponentCtrl;
