import acpPaywallsCtrl from './acp-paywalls-ctrl';
import acpPaywallsDirective from './acp-paywalls-directive';
import acpPaywalls from './acp-paywalls';
import acpPaywallsModel from './acp-paywalls-model';
import acpComponentPaywallNegativeBalance from 'components/paywall-negative-balance';
import acpComponentPaywallEsignDialog from 'components/paywall-esign-dialog';
import acpComponentPaywallCip from 'components/paywall-cip';
import acpComponentPaywallActivation from 'components/paywall-activation';
import acpComponentPaywallEsign from 'components/paywall-esign';
import acpComponentPaywallAnytimeAlerts from 'components/paywall-anytime-alerts';
import acpComponentPaywallOrderPersoCard from 'components/paywall-order-perso-card';
import acpComponentDirectDeposit from 'components/direct-deposit';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.paywalls
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-paywalls></acp-paywalls>
 * ```
 *
 * ```js
 * angular.module('acp.component.paywalls.example', [
 *   require('acp.component.paywalls').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.paywalls', [
    acpCore.name,
    acpComponentDirectDeposit.name,
    acpComponentPaywallAnytimeAlerts.name,
    acpComponentPaywallEsign.name,
    acpComponentPaywallActivation.name,
    acpComponentPaywallCip.name,
    acpComponentPaywallEsignDialog.name,
    acpComponentPaywallNegativeBalance.name,
    acpComponentPaywallOrderPersoCard.name
  ])
  .factory('acpPaywallsModel', acpPaywallsModel)
  .constant('ACP_PAYWALLS', acpPaywalls)
  .directive('acpPaywalls', acpPaywallsDirective)
  .controller('AcpPaywallComponentCtrl', acpPaywallsCtrl);

export default component;
