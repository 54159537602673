function genericReducer(relevantFeatures) {
  return function(feature) {
    if (!feature) {
      return;
    }

    var returnFeature = {};

    relevantFeatures.forEach(function(feat) {
      returnFeature[feat] = feature.features[feat] || false;
    });

    return {
      features: returnFeature
    };
  };
}

export default {
  /**
   * Paywall for Send Money (FlashPay)
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for anytime alerts.
   */
  sendMoney: {
    feature: 'webapi:send_money_paywall_gateway',
    requirements: {
      'webapi:vault_activated_card': {
        wall: 'acp-card-activation-paywall',
        order: 1
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 2
      },
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 3
      }
    },
    aliases: {
      'webapi:account_activity_p2p': {
        requirements: {
          'webapi:vault_activated_card': true,
          'webapi:cip_approved': true
        }
      }
    }
  },
  /**
   * Paywall for Savings (FlashPay)
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for savings account.
   */
  savings: {
    feature: 'webapi:savings_enrollment_gateway',
    requirements: {
      'webapi:savings_eligible_account_balance': {
        wall: 'acp-paywall-negative-balance',
        order: 3
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 1
      },
      'webapi:vault_activated_card': {
        wall: 'acp-card-activation-paywall',
        order: 2
      },
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 4
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Ingo (Mobile Check Load)
   *
   * the card activation paywall have a specific CARE that will be conditional for meijer
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for anytime alerts.
   * - been CIP approved
   */
  ingo: {
    feature: 'webapi:ingo_paywall_gateway',
    requirements: {
      'webapi:disable_optional_perso_card_order_flow': {
        wall: 'acp-card-order-paywall',
        order: 1
      },
      'webapi:any_activated_card': {
        wall: 'acp-card-activation-paywall',
        order: 2
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 3
      },
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 4
      }
    },
    aliases: {
      'webapi:activated_perso': {
        requirements: {
          'webapi:any_activated_card': true
        }
      }
    }
  },
  /**
   * Paywall for Pre-Funded Checks
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for anytime alerts.
   * - been CIP approved
   */
  checks: {
    feature: 'webapi:prefunded_checks_paywalls',
    requirements: {
      'webapi:activated_perso_required_or_exempt': {
        wall: 'acp-card-activation-paywall',
        order: 1
      },
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 2
      },
      'webapi:account_activity_prefunded_checks': {
        wall: 'acp-cip-paywall',
        order: 3
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 4
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Payback Points (aka Loyalty Points)
   *
   * Account must have:
   * - accepted esign terms
   * - been CIP approved
   */
  points: {
    feature: 'webapi:loyalty_points_gateway',
    reduce: genericReducer(['webapi:esign', 'webapi:cip_approved']),
    requirements: {
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 1
      },
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 2
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Western Union Send Money
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for anytime alerts.
   * - an activated card
   */
  wu: {
    feature: 'webapi:wu_send_paywall_gateway',
    requirements: {
      'webapi:activated_perso': {
        wall: 'acp-card-activation-paywall',
        order: 1
      },
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 2
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 3
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Western Union Load Money
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for anytime alerts.
   */
  wuLoadMoney: {
    feature: 'webapi:wu_load_paywall_gateway',
    requirements: {
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 1
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 2
      }
    },
    aliases: {}
  },
  /**
   * Paywall for My Profile
   *
   * Account must have:
   * - an activated card
   */
  manageProfile: {
    feature: 'webapi:vault_activated_card',
    requirements: {
      'webapi:vault_activated_card': {
        wall: 'acp-card-activation-paywall',
        order: 1
      }
    },
    aliases: {}
  },
  /**
   * Paywall for adding a subaccount
   *
   * Account must have:
   * - an activated card
   */
  addSubaccount: {
    feature: 'webapi:vault_activated_card',
    requirements: {
      'webapi:vault_activated_card': {
        wall: 'acp-card-activation-paywall',
        order: 1
      }
    },
    aliases: {}
  },
  /**
   * Paywall for using the PayNearMe SDK
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for anytime alerts.
   * - an activated card
   * - been CIP approved
   * - PayNearMe eligibility
   */
  payNearMe: {
    feature: 'webapi:pay_near_me_gateway',
    requirements: {
      'webapi:vault_activated_card': {
        wall: 'acp-card-activation-paywall',
        order: 1
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 2,
        reEvaluate: true
      }
    },
    reduce: genericReducer(['webapi:cip_approved', 'webapi:activated_perso']),
    aliases: {
      'webapi:activated_perso': {
        requirements: {
          'webapi:vault_activated_card': true
        }
      }
    }
  },

  /**
   * Paywall for Send Money for Accounts without Card ex: Expense Mgmt Master
   *
   * Account must have:
   * - accepted esign terms
   * - accepted/enrolled terms for anytime alerts.
   */
  sendMoneyWithoutCard: {
    feature: 'webapi:cardless_send_money_paywall',
    requirements: {
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 1
      },
      'webapi:alerts_enrolled': {
        wall: 'acp-anytime-alerts-paywall',
        order: 2
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Bank transfers
   *
   * Account must have:
   * - an activated card
   */
  bankTransfers: {
    feature: 'webapi:vault_activated_card',
    requirements: {
      'webapi:vault_activated_card': {
        wall: 'acp-card-activation-paywall',
        order: 1
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Biometrics Enrollment
   *
   * Account must have:
   * - accepted esign terms
   */
  biometricsEnrollment: {
    feature: 'webapi:biometrics_gateway',
    reduce: genericReducer(['webapi:esign']),
    requirements: {
      'webapi:esign': {
        wall: 'acp-esign-paywall',
        order: 1
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Debit Card Transfers
   *
   * Account must have:
   * - been CIP approved
   * - none of the account blocks that inhibit the Debit Card Transfers activity (e.g. DCT_ADD_FUNDS, FRAUD, CLOSED, ...)
   * - an activated perso card
   */
  debitCardTransfers: {
    feature: 'webapi:debit_card_transfer_gateway',
    requirements: {
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 1
      },
      'webapi:account_activity_debit_card_transfer': {
        wall: 'acp-cip-paywall',
        order: 2
      },
      'webapi:activated_perso': {
        wall: 'acp-card-activation-paywall',
        order: 3
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Push Money Debit Card Transfers
   *
   * Account must have:
   * - been CIP approved
   * - none of the account blocks that inhibit the Debit Card Transfers activity (e.g. DCT_ADD_FUNDS, FRAUD, CLOSED, ...)
   * - an activated perso card
   */
  pushMoneyDebitCardTransfers: {
    feature: 'webapi:push_money_debit_card_transfer_gateway',
    requirements: {
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 1
      },
      'webapi:account_activity_debit_card_transfer': {
        wall: 'acp-cip-paywall',
        order: 2
      },
      'webapi:activated_perso': {
        wall: 'acp-card-activation-paywall',
        order: 3
      }
    },
    aliases: {}
  },
  /**
   * Paywall for Pull Money Debit Card Transfers
   *
   * Account must have:
   * - been CIP approved
   * - none of the account blocks that inhibit the Debit Card Transfers activity (e.g. DCT_ADD_FUNDS, FRAUD, CLOSED, ...)
   * - an activated perso card
   */
  pullMoneyDebitCardTransfers: {
    feature: 'webapi:pull_money_debit_card_transfer_gateway',
    requirements: {
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 1
      },
      'webapi:account_activity_debit_card_transfer': {
        wall: 'acp-cip-paywall',
        order: 2
      },
      'webapi:activated_perso': {
        wall: 'acp-card-activation-paywall',
        order: 3
      }
    },
    aliases: {}
  },
  /**
   * Paywall for PayPal Transfers
   *
   * Account must have:
   * - an activated perso card
   * - been CIP approved
   */
  paypalTransfers: {
    feature: 'webapi:paypal_transfers_gateway',
    reduce: genericReducer(['webapi:activated_perso', 'webapi:cip_approved']),
    requirements: {
      'webapi:activated_perso': {
        wall: 'acp-card-activation-paywall',
        order: 1
      },
      'webapi:cip_approved': {
        wall: 'acp-cip-paywall',
        order: 2
      }
    },
    aliases: {}
  }
};
